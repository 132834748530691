import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // interact with state - dispatch actions to update the state, get stuff from state
import { Row, Col, Form, Button } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useGetOTPQuery, useVerifyOTPMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice"; // we want to loggin the user after registering
import { toast } from "react-toastify";

// multi-language supportz
import { Trans, withTranslation, useTranslation } from "react-i18next";

// we check if there is already userInfo and isVerified is true in the localstorage
// if so, we redirect (no need to verify otp again)

const OTPVerifyScreen = () => {
  const { t } = useTranslation();

  const [otpVerify, setOtpVerify] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: generatedOTP,
    refetch,
    isLoading: isLoadingGetOTP,
    error: errorGetOTP,
  } = useGetOTPQuery();
  const [verifyOTP, { isLoading }] = useVerifyOTPMutation();

  const { userInfo } = useSelector((state) => state.auth);

  // use the useLocation hook to get the query string (?redirect=/shipping)
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // sp === searchParams
  const redirect = sp.get("redirect") || "/profile"; // get the redirect query param or default to "/profile"

  useEffect(() => {
    if (userInfo && userInfo.isVerified) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo, userInfo.isVerified]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const res = await verifyOTP({ code: otpVerify }).unwrap(); // need to unwrap the promise
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <>
      {isLoadingGetOTP ? (
        <Loader />
      ) : errorGetOTP ? (
        <Message variant="danger">{errorGetOTP}</Message>
      ) : (
        <FormContainer>
          <Link className="btn btn-light my-3" to="/profile">
            {t("go-back")}
          </Link>
          <Message variant="success">
            <Trans
              i18nKey="otp-message"
              values={{
                otpExpiresAt: new Date(generatedOTP.expireTimeStamp).toString(),
              }}
            />
          </Message>
          <h1>{t("verify-otp")}</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="your-otp">
              <Form.Label>{t("your-otp")}</Form.Label>
              <Form.Control
                type="text"
                readOnly={true}
                value={generatedOTP?.code}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="otp-to-Verify">
              <Form.Label>{t("otp-to-verify")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter OTP Above"
                value={otpVerify}
                onChange={(e) => setOtpVerify(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Row>
              <Col className="d-flex justify-content-start">
                <Button
                  type="submit"
                  className="mt-2"
                  disabled={isLoading || !otpVerify}
                >
                  {t("verify-otp")}
                </Button>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button className="mt-2" onClick={() => refetch()}>
                  {t("refetch-otp")}
                </Button>
              </Col>
            </Row>
            {isLoading && <Loader />}
          </Form>
        </FormContainer>
      )}
    </>
  );
};

export default withTranslation()(OTPVerifyScreen);
