// see backend/data/benefits.js for the benefits array
const BenefitEnum = Object.freeze({
  Shipping: 0,
  Purchase: 1,
  Warranty: 2,
});

const getPercentOff = (userInfo, benefitEnum) => {
  return userInfo
    ? userInfo.membershipTier.benefits[
        benefitEnum ? benefitEnum : BenefitEnum.Purchase
      ].discountInPercent
    : 0;
};

export const getPurchasePercentOff = (userInfo) => {
  return getPercentOff(userInfo, BenefitEnum.Purchase);
};

export const getPurchaseDecimalOff = (userInfo) => {
  return (getPurchasePercentOff(userInfo) / 100).toFixed(2);
};

export const getPurchaseZheKou = (userInfo) => {
  return parseFloat(((100 - getPurchasePercentOff(userInfo)) / 10).toFixed(2));
};
