import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, ListGroup, Card } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { useCreateOrderMutation } from "../slices/orderApiSlice";
import { clearCartItems } from "../slices/cartSlice";
import CheckoutSteps from "../components/CheckoutSteps";
import ResponsiveImage from "../components/ResponsiveImage";

import {
  getPurchaseDecimalOff,
  getPurchasePercentOff,
  getPurchaseZheKou,
} from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const PlaceOrderScreen = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const [createOrder, { error, isLoading }] = useCreateOrderMutation();
  useEffect(() => {
    if (!cart.shippingAddress) {
      navigate("/shipping");
    } else if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [
    cart.paymentMethod,
    cart.shippingAddress,
    cart.shippingAddress.address,
    navigate,
  ]);

  const placeOrderHandler = async () => {
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      }).unwrap();
      console.log(JSON.stringify(res));
      if (res) {
        toast.success("Order created successfully");
        dispatch(clearCartItems());
        navigate(`/order/${res._id}`);
      }
    } catch (error) {
      toast.error(error.data?.message);
    }
  };
  return (
    <>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h1>{t("shipping")}</h1>
              <p>
                <strong>{t("address")}:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h1>{t("payment-method")}</h1>
              <strong>{t("method")}: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>{t("order-items")}</h2>
              {cart.cartItems.length === 0 ? (
                <Message>{t("cart-is-empty")}</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={2}>
                          <ResponsiveImage
                            imagePath={item.image}
                            alt={item.name}
                            rounded
                          ></ResponsiveImage>
                        </Col>
                        <Col>
                          <Link to={`/product/${item._id}`}>{item.name}</Link>
                        </Col>
                        {userInfo ? (
                          <Col md={4}>
                            {item.qty} x HK$
                            {(
                              item.price *
                              (1 - getPurchaseDecimalOff(userInfo))
                            ).toFixed(2)}{" "}
                            = HK$
                            {(
                              item.qty *
                              (
                                item.price *
                                (1 - getPurchaseDecimalOff(userInfo))
                              ).toFixed(2)
                            ).toFixed(2)}
                          </Col>
                        ) : (
                          <Col md={4}>
                            {item.qty} x HK${item.price} = HK$
                            {(item.qty * item.price).toFixed(2)}
                          </Col>
                        )}
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup>
              <ListGroup.Item>
                <h2>{t("order-summary")}</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("items-total")}:</Col>
                  <Col>
                    ${cart.itemsPrice}
                    <br />
                    <span style={{ color: "red" }}>
                      <Trans i18nKey="percent-off">
                        {{
                          percentOff: getPurchasePercentOff(userInfo),
                        }}
                        {{
                          zheKou: getPurchaseZheKou(userInfo),
                        }}
                      </Trans>
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("shipping-fee")}:</Col>
                  <Col>${cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("tax")}:</Col>
                  <Col>${cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("total")}:</Col>
                  <Col>${cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                {error && (
                  <Message variant="danger">
                    Error: {error.status} Message: {error.data?.message}
                  </Message>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  className="btn-block"
                  type="button"
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrderHandler}
                >
                  {t("place-order")}
                </Button>
                {isLoading && <Loader />}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(PlaceOrderScreen);
