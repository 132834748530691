import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";

import Loader from "./Loader";
import Message from "./Message";
import StrikeThroughPrice from "./StrikeThroughPrice";
import ResponsiveImage from "./ResponsiveImage";

import { useGetTopProductsQuery } from "../slices/productsApiSlice";
import { getPurchasePercentOff, getPurchaseZheKou } from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation } from "react-i18next";

const ProductCarousel = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const { data: products, error, isLoading } = useGetTopProductsQuery();
  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel pause="hover" className="bg-dark">
      {products.map((product) => (
        <Carousel.Item key={product._id}>
          <Link to={`/product/${product._id}`}>
            <ResponsiveImage
              imagePath={product.image}
              alt={product.name}
              cappedWidth={false}
            />

            <Carousel.Caption className="carousel-caption">
              {userInfo ? (
                <h4>
                  <Trans i18nKey="display-product-name">
                    {{ name: product.name }}
                    {{ name_zh_CN: product.name_zh_CN }}
                    {{ name_zh_HK: product.name_zh_HK }}
                  </Trans>
                  <StrikeThroughPrice
                    originalPrice={product.price}
                    percentOff={getPurchasePercentOff(userInfo)}
                    zheKou={getPurchaseZheKou(userInfo)}
                  />
                </h4>
              ) : (
                <h4>
                  <Trans i18nKey="display-product-name">
                    {{ name: product.name }}
                    {{ name_zh_CN: product.name_zh_CN }}
                    {{ name_zh_HK: product.name_zh_HK }}
                  </Trans>
                  <br />
                  (HK${product.price})
                </h4>
              )}
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default withTranslation()(ProductCarousel);
