// Outlet to return if logged in, else use Navigate
import { Outlet, Navigate } from "react-router-dom";

// useSelector to see if logged in
import { useSelector } from "react-redux";

const AdminRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);

  return userInfo && userInfo.isAdmin ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default AdminRoute;
