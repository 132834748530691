const imageSizes = [
  { width: 320, breakpoint: 767 },
  { width: 480, breakpoint: 991 },
  { width: 768, breakpoint: 1199 },
];

// const ResponsiveImage = ({ imagePath, alt }) => {
//   return (
//     <picture>
//       {imageSizes.map((size) => (
//         <source
//           key={size.width}
//           media={`(max-width: ${size.breakpoint}px)`}
//           srcSet={`${imagePath}-${size.width}px.webp`}
//           type="image/webp"
//         />
//       ))}
//       <img src={`${imagePath}-1024px.webp`} alt={alt} />
//     </picture>
//   );
// };

const ResponsiveImage = ({ imagePath, alt, rounded, cappedWidth = true }) => {
  const imageSrcSet = imageSizes
    .map((size) => {
      return `${imagePath}-${size.width}px.webp ${size.width}w`;
    })
    .join(", ");

  const imageWidth = cappedWidth ? "100%" : "auto";
  const imageHeight = cappedWidth ? "auto" : "100%";
  const rest = rounded ? { borderRadius: "10px" } : {};

  return (
    <img
      src={imagePath}
      srcSet={imageSrcSet}
      sizes={`(max-width: ${
        imageSizes[imageSizes.length - 1].breakpoint
      }px) 100vw, ${imageSizes[imageSizes.length - 1].width}px`}
      alt={alt}
      // style={{ maxWidth: `${maxWidth}px`, width: 'auto', height: '100%' }}
      style={{ width: `${imageWidth}`, height: `${imageHeight}`, ...rest }}
    />
  );
};

export default ResponsiveImage;
