import { Nav } from "react-bootstrap";
import {
  PiNumberCircleOneBold,
  PiNumberCircleTwoBold,
  PiNumberCircleThreeBold,
  PiNumberCircleFourBold,
} from "react-icons/pi";
import { LinkContainer } from "react-router-bootstrap";

// multi-language support
import { useTranslation } from 'react-i18next';

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  const { t } = useTranslation();

  return (
    <Nav className="justify-content-center mb-4">
      <Nav.Item>
        {step1 ? (
          <LinkContainer to="/login">
            <Nav.Link>
              <PiNumberCircleOneBold /> {t("sign-in")}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>
            <PiNumberCircleOneBold /> {t("sign-in")}
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/shipping">
            <Nav.Link>
              <PiNumberCircleTwoBold /> {t("shipping")}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>
            <PiNumberCircleTwoBold /> {t("shipping")}
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/payment">
            <Nav.Link>
              <PiNumberCircleThreeBold /> {t("payment")}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>
            <PiNumberCircleThreeBold /> {t("payment")}
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/placeorder">
            <Nav.Link>
              <PiNumberCircleFourBold /> {t("place-order")}
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>
            <PiNumberCircleFourBold /> {t("place-order")}
          </Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
};

export default CheckoutSteps;
