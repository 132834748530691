import { Alert } from "react-bootstrap";

// variant is like danger for error, and green for success, etc
// children is what we are wrapping
const Message = ({ variant, children }) => {
  return <Alert variant={variant}>{children}</Alert>;
};

// set the default to info
Message.defaultProps = {
  variant: "info",
};

export default Message;
