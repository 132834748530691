import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Col,
  Row,
  ListGroup,
  Card,
  Button,
  ListGroupItem,
} from "react-bootstrap";

import Magnifier from "react-magnifier";

import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import ProductInfoTab from "../components/ProductInfoTab";
import ResponsiveImage from "../components/ResponsiveImage";

import { toast } from "react-toastify";

import {
  useGetProductDetailsQuery,
  useCreateReviewMutation,
} from "../slices/productsApiSlice";
import { addToCart } from "../slices/cartSlice";

import { getPurchaseDecimalOff } from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const ProductScreen = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const navigage = useNavigate();

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const { userInfo } = useSelector((state) => state.auth);

  const [createReview, { isLoading: isLoadingCreateReview }] =
    useCreateReviewMutation();

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDesktop = viewportWidth >= 768; // Adjust the breakpoint as needed

  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty }));
    navigage("/cart");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await createReview({ productId, rating, comment }).unwrap();
      refetch();
      toast.success("Review submitted");
      setRating(0);
      setComment("");
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <>
      <Link className="btn btn-light my-3" to="/">
        {t("go-back")}
      </Link>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta
            title={product.name}
            description={product.description}
            keywords={product.keywords}
            imagePath={product.image}
          />
          <Row>
            <Col md={5}>
              {isDesktop ? (
                <Magnifier
                  src={`${product.image}-1024px.webp`}
                  alt={product.name}
                />
              ) : (
                <ResponsiveImage imagePath={product.image} alt={product.name} />
              )}
            </Col>
            <Col md={4}>
              <ProductInfoTab product={product} />
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>{t("price")}:</Col>
                      <Col>
                        {userInfo ? (
                          <strong>
                            HK$
                            {(
                              product.price *
                              (1 - getPurchaseDecimalOff(userInfo))
                            ).toFixed(2)}
                          </strong>
                        ) : (
                          <strong>HK${product.price}</strong>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroupItem>
                    <Row>
                      <Col>{t("status")}:</Col>
                      <Col>
                        {product.countInStock > 0 ? (
                          <span style={{ color: "blue" }}>{t("in-stock")}</span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {t("out-of-stock")}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>{t("quantity")}</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(Number(e.target.value))}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {" "}
                                  {x + 1}{" "}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  <ListGroupItem>
                    <Button
                      className="btn-block"
                      type="button"
                      disabled={product.countInStock === 0}
                      onClick={addToCartHandler}
                    >
                      {t("add-to-cart")}
                    </Button>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="review">
            <Col md={6}>
              <h2>{t("reviews")}</h2>
              {product.reviews.length === 0 && (
                <Message variant="info">{t("no-reviews")}</Message>
              )}
              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>{t("write-a-customer-review")}</h2>
                  {isLoadingCreateReview && <Loader />}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating" className="my-2">
                        <Form.Label>{t("rating")}</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(Number(e.target.value))}
                        >
                          <option value="">{t("select-dotdotdot")}</option>
                          <option value="1">{t("1--poor")}</option>
                          <option value="2">{t("2--fair")}</option>
                          <option value="3">{t("3--good")}</option>
                          <option value="4">{t("4--very-good")}</option>
                          <option value="5">{t("5--excellent")}</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment">
                        <Form.Label>{t("comment")}</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button type="submit" variant="primary">
                        {t("submit")}
                      </Button>
                    </Form>
                  ) : (
                    <Message variant="info">
                      <Trans i18nKey="please-sign-in-to-write-a-review">
                        <Link to="/login"></Link>
                      </Trans>
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withTranslation()(ProductScreen);
