import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from "../../slices/productsApiSlice";
import { Form, Button } from "react-bootstrap";
import FormContainer from "../../components/FormContainer";

// multi-language support
import { useTranslation } from 'react-i18next';

const ProductEditScreen = () => {
  const { t } = useTranslation();

  const { id: productId } = useParams();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState("");

  const {
    data: product,
  } = useGetProductDetailsQuery(productId);
  const [
    updateProduct,
    { isLoading: isLoadingUpdateProduct, error: errorUpdateProduct },
  ] = useUpdateProductMutation();

  const [
    uploadProductImage,
    // { isLoading: isLoadingUploadProductImage, error: errorUploadProductImage },
  ] = useUploadProductImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setImage(product.image);
      setBrand(product.brand);
      setCategory(product.category);
      setCountInStock(product.countInStock);
      setDescription(product.description);
    }
  }, [product]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedProduct = {
      productId,
      name,
      price,
      image,
      brand,
      category,
      countInStock,
      description,
    };
    try {
      await updateProduct(updatedProduct).unwrap();
      toast.success("Product updated");
      navigate("/admin/productlist");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadProductImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };
  return (
    <>
      <Link to="/admin/productlist" className="btn btn-light my-3">
        {t("go-back")}
      </Link>
      <FormContainer>
        <h1>{t("edit-product")}</h1>
        {isLoadingUpdateProduct ? (
          <Loader />
        ) : errorUpdateProduct ? (
          <Message variant="danger">{errorUpdateProduct}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-2">
              <Form.Label>{t("product-name")}</Form.Label>
              <Form.Control
                type="name"
                placeholder={t("enter-name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="price" className="my-2">
              <Form.Label>{t("price")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("enter-price")}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {/* TODO: Add image upload */}
            <Form.Group controlId="image" className="my-2">
              <Form.Label>{t("product-image")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("enter-image-url")}
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="Choose file"
                onChange={uploadFileHandler}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="brand" className="my-2">
              <Form.Label>{t("brand")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("enter-brand")}
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="countInStock" className="my-2">
              <Form.Label>{t("count-in-stock")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("enter-countInStock")}
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="category" className="my-2">
              <Form.Label>{t("category")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("enter-category")}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="product-description" className="my-2">
              <Form.Label>{t("description")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("enter-description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
            {t("update")}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
