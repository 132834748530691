import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Rating from "./Rating";
import StrikeThroughPrice from "./StrikeThroughPrice";
import ResponsiveImage from "./ResponsiveImage";

import { getPurchasePercentOff, getPurchaseZheKou } from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const Product = ({ product }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/product/${product._id}`}>
        {/* <Card.Img src={product.image}  alt={product.name} variant="top" />{" "} */}
        <Card.Img
          as={ResponsiveImage}
          imagePath={product.image}
          alt={product.name}
        />{" "}
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as="div" className="product-title">
            <Trans i18nKey="display-product-name">
              <strong>
                {{ name: product.name }}
                {{ name_zh_CN: product.name_zh_CN }}
                {{ name_zh_HK: product.name_zh_HK }}
              </strong>
            </Trans>
          </Card.Title>{" "}
        </Link>

        <Card.Text as="div">
          <Rating
            value={product.rating}
            text={`${product.numReviews} ${t("reviews")}`}
          />
        </Card.Text>

        {userInfo ? (
          <Card.Text as="div">
            <StrikeThroughPrice
              originalPrice={product.price}
              percentOff={getPurchasePercentOff(userInfo)}
              zheKou={getPurchaseZheKou(userInfo)}
            />
          </Card.Text>
        ) : (
          <Card.Text>
            {t("price")}: ${product.price}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(Product);
