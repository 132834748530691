import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  useGetUserDetailsQuery,
  useUpdateUserMutation,
} from "../../slices/usersApiSlice";
import { Form, Button } from "react-bootstrap";
import FormContainer from "../../components/FormContainer";

// multi-language support
import { useTranslation } from 'react-i18next';


const UserEditScreen = () => {
  const { t } = useTranslation();

  const { id: userId } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const {
    data: user,
    isLoading: isLoadingGetUserDetails,
    error: errorGetUserDetails,
  } = useGetUserDetailsQuery(userId);
  const [
    updateUser,
  ] = useUpdateUserMutation(userId);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setIsAdmin(user.isAdmin);
    }
    // console.log(name, email, isAdmin);
  }, [user]);

  const submitHandler = async (e) => {
    // console.log("submitHandler");
    e.preventDefault();
    try {
      await updateUser({
        userId,
        name,
        email,
        isAdmin,
      });
      toast.success(`User ${userId} updated`);
      navigate("/admin/userlist");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <>
      <Link to="/admin/userlist" className="btn btn-light my-3">
        {t("go-back")}
      </Link>
      <FormContainer>
        <h1>{t("edit-user")}</h1>
        {isLoadingGetUserDetails ? (
          <Loader />
        ) : errorGetUserDetails ? (
          <Message variant="danger">{errorGetUserDetails}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-2">
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="name"
                placeholder={t("enter-name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="email" className="my-2">
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("enter-email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="isAdmin" className="my-2">
              <Form.Check
                type="checkbox"
                label={t("is-admin-q")}
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
            {t("update")}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default UserEditScreen;
