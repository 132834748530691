import { getPurchaseDecimalOff } from "./memberUtils";

export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2); // round to 2 decimal places (e.g. 1.00)
};

export const updateCart = (state) => {
  // fetch the user info from local storage
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  // calculate the price multiple
  const priceMultiple = (
    userInfo && userInfo.membershipTier
      ? 1 - getPurchaseDecimalOff(userInfo)
      : 1
  ).toFixed(2);

  // calculate the item price
  state.itemsPrice = addDecimals(
    state.cartItems.reduce(
      (acc, item) => acc + item.price * priceMultiple * item.qty,
      0 // default for the acc(umulator)
    )
  );
  // calculate the shipping price (if order > $100, free, else $10)
  state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 10);
  // calculate the tax price (15% tax)
  state.taxPrice = addDecimals(Number((0.15 * state.itemsPrice).toFixed(2)));

  // calculate the total price
  state.totalPrice = (
    Number(state.itemsPrice) +
    Number(state.shippingPrice) +
    Number(state.taxPrice)
  ).toFixed(2);

  // save everything to local storage
  localStorage.setItem("cart", JSON.stringify(state));

  return state;
};
