import { Link, useParams } from "react-router-dom"; // useParams get the orderId from the url
import { Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import Message from "../components/Message";
import Loader from "../components/Loader";
import ResponsiveImage from "../components/ResponsiveImage";

import {
  useGetOrderDetailsQuery,
  usePayOrderMutation,
  useGetPaypalClientIdQuery,
  useDeliverOrderMutation,
} from "../slices/orderApiSlice";

import {
  getPurchaseDecimalOff,
  getPurchasePercentOff,
  getPurchaseZheKou,
} from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const OrderScreen = () => {
  const { t } = useTranslation();

  const { id: orderId } = useParams();
  const {
    data: order,
    refetch, // refetch the order so we don't get the stale data
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);

  const [payOrder, { isLoading: isLoadingPay }] = usePayOrderMutation();
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();
  const {
    data: paypal,
    isLoading: isLoadingPayPal,
    error: errorPayPal,
  } = useGetPaypalClientIdQuery();
  const [deliverOrder, { isLoading: isLoadingDeliver }] =
    useDeliverOrderMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!errorPayPal && !isLoadingPayPal && paypal.clientId) {
      const loadPayPalScript = async () => {
        paypalDispatch({
          type: "resetOptions",
          value: { "client-id": paypal.clientId, currency: "USD" },
        });
        paypalDispatch({ type: "setLoadingStatus", value: "pending" });
      };
      if (order && !order.isPaid) {
        if (!window.paypal) {
          loadPayPalScript();
        }
      }
    }
  }, [errorPayPal, isLoadingPayPal, paypal, order, paypalDispatch]);

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: order.totalPrice,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  function onApprove(data, actions) {
    // details come from the paypal button
    return actions.order.capture().then(async function (details) {
      try {
        await payOrder({ orderId, details });
        refetch();
        toast.success("Payment Successful!");
      } catch (error) {
        toast.error(error?.data?.message || error.message);
      }
    });
  }

  function onError(err) {
    toast.error(err?.data?.message || err.message);
  }

  async function onApproveTest() {
    await payOrder({ orderId, details: { payer: {} } });
    refetch();
    toast.success("Payment Successful!");
  }

  const deliverOrderHandler = async () => {
    try {
      await deliverOrder(orderId);
      refetch();
      toast.success("Order Delivered!");
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  // console.log(order);
  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1>Order {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>{t("shipping")}</h2>
              <p>
                <strong>{t("name")}: </strong> {order.user.name}
              </p>
              <p>
                <strong>{t("email-address")}: </strong>{" "}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>{t("address")}:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  {t("delivered-on")} {order.deliveredAt}
                </Message>
              ) : (
                <Message variant="danger">{t("not-delivered")}</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t("payment-method")}</h2>
              <p>
                <strong>{t("method")}:</strong> {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">
                  {t("paid-on")}:
                  {new Intl.DateTimeFormat("zh-HK", {
                    dateStyle: "full",
                    timeStyle: "long",
                    timeZone: "Asia/Hong_Kong",
                  }).format(new Date(order.paidAt))}
                </Message>
              ) : (
                <Message variant="danger">{t("not-paid")}</Message>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>{t("items")}</h2>
              {order.orderItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Row>
                    <Col md={1}>
                      <ResponsiveImage
                        imagePath={item.image}
                        alt={item.name}
                        rounded
                      />
                    </Col>

                    <Col>
                      <Link to={`/product/${item._id}`}>{item.name}</Link>
                    </Col>

                    {userInfo ? (
                      <Col md={4}>
                        {item.qty} x HK$
                        {(
                          item.price *
                          (1 - getPurchaseDecimalOff(userInfo))
                        ).toFixed(2)}{" "}
                        = HK$
                        {(
                          item.qty *
                          (
                            item.price *
                            (1 - getPurchaseDecimalOff(userInfo))
                          ).toFixed(2)
                        ).toFixed(2)}
                      </Col>
                    ) : (
                      <Col md={4}>
                        {item.qty} x HK${item.price} = HK$
                        {(item.qty * item.price).toFixed(2)}
                      </Col>
                    )}
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>{t("order-summary")}</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("items")}</Col>
                  <Col>
                    $ {order.itemsPrice.toFixed(2)}
                    <br />
                    <span style={{ color: "red" }}>
                      <Trans i18nKey="percent-off">
                        {{
                          percentOff: getPurchasePercentOff(userInfo),
                        }}
                        {{
                          zheKou: getPurchaseZheKou(userInfo),
                        }}
                      </Trans>
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("shipping")}</Col>
                  <Col>$ {order.shippingPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("tax")}</Col>
                  <Col>$ {order.taxPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t("total")}</Col>
                  <Col>$ {order.totalPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>
              {
                /* PAY ORDER PLACEHOLDER */
                !order.isPaid && (
                  <ListGroup.Item>
                    {isLoadingPay && <Loader />}
                    {isPending ? (
                      <Loader />
                    ) : (
                      <div>
                        <Button
                          onClick={onApproveTest}
                          style={{ marginBottom: "10px" }}
                        >
                          {t("test-pay-order")}
                        </Button>
                        <div>
                          <PayPalButtons
                            style={{ layout: "horizontal" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                          ></PayPalButtons>
                        </div>
                      </div>
                    )}
                  </ListGroup.Item>
                )
              }
              {isLoadingDeliver && <Loader />}
              {
                /* MARK AS DELIVERED PLACEHOLDER */
                userInfo &&
                  userInfo.isAdmin &&
                  order.isPaid &&
                  !order.isDelivered && (
                    <ListGroup.Item>
                      <Button
                        type="button"
                        className="btn btn-block"
                        onClick={deliverOrderHandler}
                      >
                        {t("mark-as-delivered")}
                      </Button>
                    </ListGroup.Item>
                  )
              }
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(OrderScreen);
