import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords, imagePath }) => {
  const baseUrl = window.location.origin;
  const ogImage = `${baseUrl}${imagePath}-320px.webp`;
  const sharedKeywords =
    "plants, spiky, dry, resilient, cactus, succulent, dirt cheap,植物,尖刺,干燥,有弹性,仙人掌,多汁,非常便宜,植物,尖刺,乾燥,有彈性,仙人掌,多汁,非常便宜";
  const finalKeywords = keywords
    ? `${keywords},${sharedKeywords}`
    : sharedKeywords;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}></meta>
      <meta name="keywords" content={finalKeywords}></meta>
      <meta property="og:site_name" name="og:site_name" content={title} />
      <meta property="og:title" name="og:title" content={title} />
      <meta property="og:description" name="og:description" content={description} />
      <meta property="og:keywords" name="og:keywords" content={finalKeywords} />
      <meta property="og:image" name="og:image" content={ogImage} />
      <meta property="og:image:secure_url" name="og:image:secure_url" content={ogImage} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Welcome to PriceShop",
  description: "We sell the best cacti for dirt cheap price",
  keywords: "home",
  imagePath: "/images/priceshopxyz-poster",
};

export default Meta;
