import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, Form, Button, Accordion } from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { LinkContainer } from "react-router-bootstrap";
import { toast } from "react-toastify";

import { TbShieldLock } from "react-icons/tb";
import { BsPersonCheck } from "react-icons/bs";

import Message from "./Message";
import Loader from "./Loader";

import { useGetPointQuery, useProfileMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const UserInfoTabs = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [tooltipContent, setTooltipContent] = useState(t("click-to-copy"));

  const [membershipTier, setMembershipTier] = useState({});
  const [referredBy, setReferredBy] = useState({});

  const baseUrl = window.location.origin;

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [updateProfile, { isLoading: isLoadingUpdateProfile }] =
    useProfileMutation();
  const {
    data: point,
    refetch,
    isLoading: isLoadingGetPoint,
    error: errorGetPoint,
  } = useGetPointQuery();

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email);
      setName(userInfo.name);
      setReferralCode(userInfo.referralCode);
      setIsVerified(userInfo.isVerified);
      setMembershipTier(userInfo.membershipTier);
      setReferredBy(userInfo.referredBy);
    }
  }, [
    userInfo,
    userInfo.referralCode,
    userInfo.name,
    userInfo.email,
    userInfo.membershipTier,
    userInfo.referredBy,
  ]);

  useEffect(() => {
    if (point) {
      refetch();
    }
  }, [refetch]);

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(`${baseUrl}/register?referral=${text}`);
      setTooltipContent(t("copied-to-clipboard"));
    } catch (error) {
      setTooltipContent(t("copy-to-clipboard-failed"));
    }
    setTimeout(() => {
      setTooltipContent(t("click-to-copy"));
    }, 2000);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          password,
        }).unwrap();
        dispatch(setCredentials(res));
        toast.success("Profile updated");
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const renderTooltip = (props) => (
    <Tooltip {...props}>{tooltipContent}</Tooltip>
  );

  return (
    <Tabs defaultActiveKey="security" id="user-info-tabs">
      <Tab
        eventKey="security"
        title={
          <>
            <TbShieldLock /> {t("security")}
          </>
        }
      >
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name" className="my-2">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control
              type="name"
              placeholder={t("enter-name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="email" className="my-2">
            <Form.Label>{t("email-address")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("enter-email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="password" className="my-2">
            <Form.Label>{t("password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("enter-password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="confirmPassword" className="my-2">
            <Form.Label>{t("confirm-password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("enter-confirm-password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          {isVerified || (
            <Message variant="info">
              {t("verify-otp-message")}{" "}
              <LinkContainer to="/verifyotp">
                <Button variant="link">{t("verify-otp")}</Button>
              </LinkContainer>
            </Message>
          )}

          <Button type="submit" variant="primary" className="my-2">
            {t("update")}
          </Button>
          {isLoadingUpdateProfile && <Loader />}
        </Form>
      </Tab>
      <Tab
        eventKey="member-status"
        title={
          <>
            <BsPersonCheck /> {t("member-status")}
          </>
        }
      >
        <div className="mt-3">
          <h5>
            <Trans i18nKey="membership-tier">
              <span style={{ color: "red" }}>
                {{ name: membershipTier.name }}
                {{ name_zh_CN: membershipTier.name_zh_CN }}
                {{ name_zh_HK: membershipTier.name_zh_HK }}
              </span>
            </Trans>
          </h5>
          <h6>
            <Trans i18nKey="membership-tier-description">
              <p>
                {{ description: membershipTier.description }}
                {{ description_zh_CN: membershipTier.description_zh_CN }}
                {{ description_zh_HK: membershipTier.description_zh_HK }}
              </p>
            </Trans>
          </h6>

          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("benefits")}:</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {membershipTier.benefits &&
                      membershipTier.benefits.map((benefit, index) => (
                        <li key={index}>
                          <Trans i18nKey="membertier-benefits-details">
                            <span style={{ color: "red" }}>
                              {{ type: benefit.type }}
                              {{ description: benefit.description }}
                              {{ type_zh_CN: benefit.type_zh_CN }}
                              {{ description_zh_CN: benefit.description_zh_CN }}
                              {{ type_zh_HK: benefit.type_zh_HK }}
                              {{ description_zh_HK: benefit.description_zh_HK }}
                              {{
                                discountInPercent: benefit.discountInPercent,
                              }}
                              {{ valueOver: benefit.valueOver }}
                            </span>
                          </Trans>
                        </li>
                      ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <br />
          <h6>
            {t("my-referrer")}:{" "}
            <span style={{ color: "red" }}>
              {referredBy ? referredBy.name : "None"}
            </span>
          </h6>
          <>
            <h6>
              {t("my-referral-code")}:{" "}
              <OverlayTrigger placement="right" overlay={renderTooltip}>
                <span
                  style={{
                    color: "red",
                    cursor: "pointer",
                    borderBottom: "1px dotted",
                  }}
                  onClick={() => copyToClipboard(referralCode)}
                >
                  {referralCode}
                </span>
              </OverlayTrigger>
            </h6>
          </>
          {isLoadingGetPoint ? (
            <Loader />
          ) : errorGetPoint ? (
            <Message variant="danger">{errorGetPoint}</Message>
          ) : (
            <>
              <h6>
                {t("my-point")}:{" "}
                <span style={{ color: "red" }}>{point.pointsAvail}</span>
              </h6>
              <h6>
                {t("my-point-expiry")}:{" "}
                <span style={{ color: "red" }}>
                  {new Date(point.expireTimeStamp).toString()}
                </span>
              </h6>
              <ul>
                {point.pointsRequiredPerTier &&
                  point.pointsRequiredPerTier.map((pointTier, index) => (
                    <li key={index}>
                      <Trans i18nKey="membertier-point-difference">
                        <span style={{ color: "red" }}>
                          {{ membershipTier: pointTier.name }}
                          {{ membershipTier_zh_CN: pointTier.name_zh_CN }}
                          {{ membershipTier_zh_HK: pointTier.name_zh_HK }}
                          {{
                            difference: new Intl.NumberFormat("zh-HK").format(
                              pointTier.points - point.pointsAvail > 0
                                ? pointTier.points - point.pointsAvail
                                : 0
                            ),
                          }}
                        </span>
                      </Trans>
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      </Tab>
    </Tabs>
  );
};

export default withTranslation()(UserInfoTabs);
