import { useNavigate } from "react-router-dom";
import { Badge, Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice"; // we need to import the logout mutation
import SearchBox from "./SearchBox";

import { logout } from "../slices/authSlice"; // we need to import the logout action creator

import logo from "../assets/logo2.png";

import LanguageSelector from "./LanguageSelector";

// multi-language supportz
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();

  const { cartItems } = useSelector((state) => state.cart); // take a look at the store.js file
  const { userInfo } = useSelector((state) => state.auth); // take a look at the store.js file

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation(); // we need to destructure the logout mutation

  const logoutHandler = async () => {
    // console.log("logoutHandler");
    try {
      await logoutApiCall().unwrap(); // we need to unwrap the promise
      dispatch(logout()); // we need to dispatch the logout action creator (local storage clearing)
      navigate("/login"); // we need to navigate to the login page
    } catch (error) {
      console.log(error);
    }
    // DISPATCH LOGOUT
  };

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="md" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={logo} alt="PriceShop" />
              {t("price-shop")}
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LanguageSelector />
              <SearchBox />
              <LinkContainer to="/cart">
                <Nav.Link>
                  <FaShoppingCart /> {t("cart")}
                  {cartItems.length > 0 && (
                    <Badge pill bg="success" style={{ marginLeft: "5px" }}>
                      {cartItems.reduce((acc, item) => acc + item.qty, 0)}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>{t("profile")}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                  {t("sign-out")}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <FaUser /> {t("sign-in")}
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title={t("admin-console")} id="adminmenu">
                  <LinkContainer to="/admin/productlist">
                    <NavDropdown.Item>{t("products")}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/userlist">
                    <NavDropdown.Item>{t("users")}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/orderlist">
                    <NavDropdown.Item>{t("orders")}</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
export default Header;
