import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en_US",
  lng: "zh_CN",
  resources: {
    en_US: {
      translations: require("./locales/en_US/translations.json"),
    },
    zh_CN: {
      translations: require("./locales/zh_CN/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en_US", "zh_CN"];

export default i18n;
