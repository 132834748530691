import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { initGA, logPageView } from "./components/GoogleAnalytics";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  // Read this on why we use useLocation to log page views
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  const location = useLocation();
  useEffect(() => {
    initGA(); // Initialize Google Analytics
    logPageView(); // Log the initial pageview
  }, []);

  useEffect(() => {
    logPageView(); // Log on location change
  }, [location]);

  return (
    <>
      <Header />
      <main className="py-3">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
      <ToastContainer />
      {/* <GoogleAnalytics trackingId="G-L3N3ZET4VL" /> */}
    </>
  );
};

export default App;
