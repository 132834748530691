import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // interact with state - dispatch actions to update the state, get stuff from state
import { Form, Button, Row, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";

// multi-language support
import { useTranslation } from "react-i18next";

// we check if there is already userInfo in the localstorage
// if so, we redirect (no need to login again)

const LoginScreen = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  // use the useLocation hook to get the query string (?redirect=/shipping)
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // sp === searchParams
  const redirect = sp.get("redirect") || "/"; // get the redirect query param or default to "/"

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // DISPATCH LOGIN
    // console.log("submitHandler");
    try {
      const res = await login({ email, password }).unwrap(); // need to unwrap the promise
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <FormContainer>
      <h1>{t("sign-in")}</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>{t("email-address")}</Form.Label>
          <Form.Control
            type="email"
            placeholder="john@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>{t("password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder="123456"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mt-2"
          disabled={isLoading}
        >
          {t("sign-in")}
        </Button>

        {isLoading && <Loader />}
      </Form>
      <Row className="py-3">
        <Col>
          {t("new-customer")}?{" "}
          <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
            {t("sign-up")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default LoginScreen;
