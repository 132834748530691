import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { FaTimes, FaTrash, FaEdit, FaCheck } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

import {
  useGetUsersQuery,
  useDeleteUserMutation,
} from "../../slices/usersApiSlice";

// multi-language support
import { useTranslation } from "react-i18next";

const UserListScreen = () => {
  const { t } = useTranslation();

  const {
    data: users,
    refetch,
    isLoading: isLoadingGetUsers,
    error: errorGetUsers,
  } = useGetUsersQuery();

  const [deleteUser, { isLoading: isLoadingDelete }] = useDeleteUserMutation();

  const deleteHandler = async (id) => {
    // console.log(`deleteHandler: ${id}`);
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        await deleteUser(id);
        toast.success(`User ${id} deleted`);
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  return (
    <>
      <h1>{t("users")}</h1>
      {isLoadingDelete && <Loader />}
      {isLoadingGetUsers ? (
        <Loader />
      ) : errorGetUsers ? (
        <Message variant="danger">{errorGetUsers}</Message>
      ) : (
        <Table striped hover responsive className="table-sm">
          <thead>
            <tr>
              <th>{t("id")}</th>
              <th>{t("user-name")}</th>
              <th>{t("email")}</th>
              <th>{t("verified")}</th>
              <th>{t("membership")}</th>
              <th>{t("referred-by")}</th>
              <th>{t("admin")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.name}</td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>
                  {user.isVerified ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}
                </td>
                <td>{user.membershipTier.name}</td>
                <td>
                  {user.referredBy ? user.referredBy.name : "No Referral"}
                </td>
                <td>
                  {user.isAdmin ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}
                </td>
                <td>
                  <LinkContainer to={`/admin/user/${user._id}/edit`}>
                    <Button className="btn-sm" variant="light">
                      <FaEdit />
                    </Button>
                  </LinkContainer>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => deleteHandler(user._id)}
                  >
                    <FaTrash style={{ color: "white" }} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default UserListScreen;
