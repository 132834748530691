// GoogleAnalytics.js
import ReactGA from "react-ga";

export const initGA = () => {
  // ReactGA.initialize("G-L3N3ZET4VL", { debug: true });
  ReactGA.initialize("G-L3N3ZET4VL");
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
  });
};
