import React from "react";
import PropTypes from "prop-types";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const StrikeThroughPrice = ({ originalPrice, percentOff, zheKou }) => {
  const { t } = useTranslation();

  const originalPriceFormatted = new Intl.NumberFormat("zh-HK", {
    style: "currency",
    currency: "HKD",
  }).format(originalPrice);

  const salePrice = (originalPrice * (1 - percentOff / 100)).toFixed(2);
  const salePriceFormatted = new Intl.NumberFormat("zh-HK", {
    style: "currency",
    currency: "HKD",
  }).format(salePrice);

  return (
    <div style={{ position: "relative" }}>
      <span style={{ textDecoration: "line-through", marginRight: "0.5rem" }}>
        {t("original-price")}: {originalPriceFormatted}
      </span>
      <br />
      <span style={{ color: "red" }}>
        <Trans i18nKey="your-price">
          {{ salePriceFormatted }} {{ percentOff }} {{ zheKou }}
        </Trans>
      </span>
    </div>
  );
};

StrikeThroughPrice.propTypes = {
  originalPrice: PropTypes.number.isRequired,
  percentOff: PropTypes.number.isRequired,
  zheKou: PropTypes.number.isRequired,
};

export default withTranslation()(StrikeThroughPrice);
