import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../slices/cartSlice";

// multi-language support
import { useTranslation } from 'react-i18next';

// Need to redirect to shipping if no shipping address
const PaymentScreen = () => {
  const { t } = useTranslation();

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  useEffect(() => {
    if (!shippingAddress.address) {
      navigate("/shipping");
    }
  }, [shippingAddress, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log("submitHandler");
    dispatch(savePaymentMethod(paymentMethod));
    navigate("/placeorder");
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h1>{t("payment-method")}</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="my-2">
          <Form.Label as="legend">{t("select-method")}</Form.Label>
          <Col>
            <Form.Check
              type="radio"
              className="my-2"
              label="AliPay"
              id="AliPay"
              name="paymentMethod"
              value="AliPay"
              checked={paymentMethod === "AliPay"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>
            <Form.Check
              type="radio"
              className="my-2"
              label="PayPal or Credit Card"
              id="PayPal"
              name="paymentMethod"
              value="PayPal"
              checked={paymentMethod === "PayPal"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>
          </Col>
        </Form.Group>
        <Button type="submit" variant="primary" className="my-2">
        {t("continue")}
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;
