import { useSelector } from "react-redux";
import { Tab, Tabs, ListGroup, ListGroupItem } from "react-bootstrap";

import { TbListDetails } from "react-icons/tb";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";

import Rating from "./Rating";
import StrikeThroughPrice from "./StrikeThroughPrice";

import { getPurchasePercentOff, getPurchaseZheKou } from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const ProductInfoTabs = ({ product }) => {
  const { t } = useTranslation();

  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Tabs defaultActiveKey="info" id="product-info-tabs">
      <Tab
        eventKey="info"
        title={
          <>
            <TbListDetails /> {t("product-info")}
          </>
        }
      >
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Trans i18nKey="display-product-name">
              <h3>
                {{ name: product.name }}
                {{ name_zh_CN: product.name_zh_CN }}
                {{ name_zh_HK: product.name_zh_HK }}
              </h3>
            </Trans>
          </ListGroup.Item>
          <ListGroup.Item>
            <Rating
              value={product.rating}
              text={`${product.numReviews} ${t("reviews")}`}
            />
          </ListGroup.Item>
          {userInfo ? (
            <ListGroup.Item>
              <StrikeThroughPrice
                originalPrice={product.price}
                percentOff={getPurchasePercentOff(userInfo)}
                zheKou={getPurchaseZheKou(userInfo)}
              />
            </ListGroup.Item>
          ) : (
            <ListGroup.Item>
              {t("price")}: ${product.price}
            </ListGroup.Item>
          )}
          <ListGroupItem>
            <Trans i18nKey="display-product-description">
              {{ description: product.description }}
              {{ description_zh_CN: product.description_zh_CN }}
              {{ description_zh_HK: product.description_zh_HK }}
            </Trans>
          </ListGroupItem>
        </ListGroup>
      </Tab>
      <Tab
        eventKey="specification"
        title={
          <>
            <GiPerspectiveDiceSixFacesRandom /> {t("product-specification")}
          </>
        }
      >
        <div className="mt-3">
          <h5>
            <Trans i18nKey="product-height">
              <span style={{ color: "red" }}>
                {{ height: product?.spec?.heightInInches }}
              </span>
            </Trans>
          </h5>
          <h5>
            <Trans i18nKey="product-width">
              <span style={{ color: "red" }}>
                {{ width: product?.spec?.widthInInches }}
              </span>
            </Trans>
          </h5>
          <h5>
            <Trans i18nKey="product-depth">
              <span style={{ color: "red" }}>
                {{ depth: product?.spec?.depthInInches }}
              </span>
            </Trans>
          </h5>
          <h5>
            <Trans i18nKey="product-weight">
              <span style={{ color: "red" }}>
                {{ weight: product?.spec?.weightInLbs }}
              </span>
            </Trans>
          </h5>
          <h5>
            <Trans i18nKey="product-special-notes">
              <span style={{ color: "red" }}>
                {{ specialNotes: product?.spec?.specialNotes }}
                {{ specialNotes_zh_CN: product?.spec?.specialNotes_zh_CN }}
                {{ specialNotes_zh_HK: product?.spec?.specialNotes_zh_HK }}
              </span>
            </Trans>
          </h5>
        </div>
      </Tab>
    </Tabs>
  );
};

export default withTranslation()(ProductInfoTabs);
