import { createSlice } from "@reduxjs/toolkit"; // since we don't deal with async endpoint, we can use createSlice
import { updateCart } from "../utils/cartUtils";

// get the cart from localStorage to see if it is there
const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : { cartItems: [], shippingAddress: {}, paymentMethod: "PayPal" }; // can implement using stripe or other providers as well

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x._id === item._id);
      if (existItem) {
        state.cartItems = state.cartItems.map((x) =>
          x._id === existItem._id ? item : x
        );
      } else {
        state.cartItems = [...state.cartItems, item];
      }

      // update state (price and save to localstorage)
      return updateCart(state);
    },
    removeFromCart: (state, action) => {
      // action.payload is the one that i want to delete
      state.cartItems = state.cartItems.filter((x) => x._id !== action.payload);
      return updateCart(state);
    },
    saveShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
      return updateCart(state);
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      return updateCart(state);
    },
    clearCartItems: (state) => {
      state.cartItems = [];
      return updateCart(state);
    },
  }, // reducers are functions that take in state and action and return a new state
});

// we need to export as action for this to work
export const {
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
  clearCartItems,
} = cartSlice.actions; // export the action

export default cartSlice.reducer; // export the reducer
