import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Paginate from "../../components/Paginate";
import {
  useGetProductsQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
} from "../../slices/productsApiSlice";
import { toast } from "react-toastify";

// multi-language support
import { useTranslation } from 'react-i18next';


const ProductListScreen = () => {
  const { t } = useTranslation();

  const { pageNumber } = useParams();
  const {
    data,
    refetch,
    isLoading: isLoadingGetProducts,
    error,
  } = useGetProductsQuery({ pageNumber });
  // console.log(products)
  const [createProduct, { isLoading: isLoadingCreate }] =
    useCreateProductMutation();

  const [deleteProduct, { isLoading: isLoadingDelete }] =
    useDeleteProductMutation();

  const deleteHandler = async (id) => {
    // console.log(`deleteHandler: ${id}`);
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        await deleteProduct(id);
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const createProductHandler = async () => {
    if (window.confirm("Are you sure you want to create?")) {
      try {
        await createProduct();
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>{t("products")}</h1>
        </Col>
        <Col className="text-end">
          {/* <LinkContainer to="/admin/product/create"> */}
          <Button className="my-3" onClick={createProductHandler}>
            <FaEdit /> {t("create-product")}
          </Button>
          {/* </LinkContainer> */}
        </Col>
      </Row>

      {isLoadingCreate && <Loader />}
      {isLoadingDelete && <Loader />}
      {isLoadingGetProducts ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>{t("id")}</th>
                <th>{t("product-name")}</th>
                <th>{t("price")}</th>
                <th>{t("category")}</th>
                <th>{t("brand")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.products?.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm mx-2"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <FaTrash style={{ color: "white" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default ProductListScreen;
