import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div>
      <button onClick={changeLanguage} value="en_US">
        English
      </button>
      <button onClick={changeLanguage} value="zh_CN">
        简体中文
      </button>
    </div>
  );
};

export default LanguageSelector;
