import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import Message from "../components/Message";
import ResponsiveImage from "../components/ResponsiveImage";

import { addToCart, removeFromCart } from "../slices/cartSlice";
import {
  getPurchaseDecimalOff,
  getPurchasePercentOff,
  getPurchaseZheKou,
} from "../utils/memberUtils";

// multi-language support
import { Trans, withTranslation, useTranslation } from "react-i18next";

const CartScreen = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const addToCartHandler = async (item, qty) => {
    dispatch(addToCart({ ...item, qty }));
  };

  const removeToCartHandler = async (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/login?redirect=/shipping");
  };

  return (
    <Row>
      <Col md={8}>
        <h1 style={{ marginBottom: "20px" }}>{t("shopping-cart")}</h1>
        {cartItems.length === 0 ? (
          <Message>
            {t("cart-is-empty")} <Link to="/">{t("go-back")}</Link>
          </Message>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item._id}>
                {" "}
                <Row>
                  <Col md={2}>
                    <ResponsiveImage
                      imagePath={item.image}
                      alt={item.name}
                      rounded
                    />
                  </Col>
                  <Col md={3} style={{ marginTop: "20px" }}>
                    <Link to={`/product/${item._id}`}>{item.name}</Link>
                  </Col>
                  <Col md={2} style={{ marginTop: "20px" }}>
                    {userInfo ? (
                      <>
                        <strong>
                          HK$
                          {(
                            item.price *
                            (1 - getPurchaseDecimalOff(userInfo))
                          ).toFixed(2)}
                        </strong>
                        <br />
                        <span style={{ color: "red" }}>
                          <Trans i18nKey="percent-off">
                            {{
                              percentOff: getPurchasePercentOff(userInfo),
                            }}
                            {{
                              zheKou: getPurchaseZheKou(userInfo),
                            }}
                          </Trans>
                        </span>
                      </>
                    ) : (
                      <strong>HK${item.price}</strong>
                    )}
                  </Col>
                  <Col md={2} style={{ marginTop: "20px" }}>
                    <Form.Control
                      as="select"
                      value={item.qty}
                      onChange={(e) =>
                        addToCartHandler(item, Number(e.target.value))
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {" "}
                          {x + 1}{" "}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={2} style={{ marginTop: "20px" }}>
                    <Button
                      type="button"
                      variant="light"
                      onClick={() => removeToCartHandler(item._id)} // need to use a callback function here (arrow function), not a function call
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>
                {t("subtotal")} (
                {cartItems.reduce((acc, item) => acc + item.qty, 0)})
                {t("items")}
              </h2>
              HK$
              {userInfo ? (
                <span>
                  {cartItems
                    .reduce(
                      (acc, item) =>
                        acc +
                        item.qty *
                          item.price *
                          (1 - getPurchaseDecimalOff(userInfo)),
                      0
                    )
                    .toFixed(2)}
                </span>
              ) : (
                <span>
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)}
                </span>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                type="button"
                className="btn-block"
                disabled={cartItems.length === 0}
                onClick={() => checkoutHandler()}
              >
                {t("proceed-to-checkout")}
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default withTranslation()(CartScreen);
