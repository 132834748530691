import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // interact with state - dispatch actions to update the state, get stuff from state
import { Form, Button, Row, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice"; // we want to loggin the user after registering
import { toast } from "react-toastify";

// multi-language supportz
import { Trans, withTranslation, useTranslation } from "react-i18next";

// we check if there is already userInfo in the localstorage
// if so, we redirect (no need to register again)

const RegisterScreen = () => {
  const { t } = useTranslation();

  // use the useLocation hook to get the query string (?redirect=/shipping)
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // sp === searchParams
  const redirect = sp.get("redirect") || "/"; // get the redirect query param or default to "/"
  const referral = sp.get("referral") || ""; // get the referral (code) query param if exists or default to ""

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState(referral);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else {
      // DISPATCH REGISTER
      // console.log("submitHandler");
      try {
        const res = await register({
          name,
          email,
          password,
          referralCode,
        }).unwrap(); // need to unwrap the promise
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  return (
    <FormContainer>
      <h1>{t("sign-up")}</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>{t("name")}</Form.Label>
          <Form.Control
            type="name"
            placeholder={t("enter-name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>{t("email-address")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("enter-email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>{t("password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("enter-password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>{t("confirm-password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("enter-confirm-password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="referralCode">
          <Form.Label>{t("referral-code")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("enter-referral-code")}
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          ></Form.Control>
        </Form.Group>{" "}
        <Button
          type="submit"
          variant="primary"
          className="mt-2"
          disabled={isLoading}
        >
          {t("sign-up")}
        </Button>
        {isLoading && <Loader />}
      </Form>
      <Row className="py-3">
        <Col>
          <Trans i18nKey="already-have-an-account-q">
            <Link
              to={redirect ? `/login?redirect=${redirect}` : "/login"}
            ></Link>
          </Trans>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default withTranslation()(RegisterScreen);
